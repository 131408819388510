import React from 'react';

//STYLES
import '../Styles/GlobalStyle.css';

function Twitter(props){
    // console.log('props', props)
	  
	return (
		<div className="container">
            Gracias
        </div>
	);
};

export default Twitter;