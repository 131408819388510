import React from 'react'
import { Timeline, TimelineItem, TimelineSeparator, TimelineContent, TimelineDot } from '@material-ui/lab';

const Steps = () => {
    return (
        <div style={{margin:"1.3em 1em"}}>
            {/* <p className={"trivia-gsFont"}>Acepta los teéminos y lee las políticas de privacidad</p>
            <p className={"trivia-gsFont"}>Inicia sesión con tu cuenta de Facebook</p>
            <p className={"trivia-gsFont"}>Responde correctamente las 10 preguntas en el menor tiempo posible</p> */}
            <Timeline style={{padding:"0", margin:"0"}}>
                <TimelineItem className={"trivia-stepsContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-steps"}>
                        01
                    </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gsFont"} style={{marginTop:"0.5em"}}>Lee las políticas de privacidad y acepta los términos</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-stepsContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-steps"}>
                        02
                    </TimelineDot>
                    </TimelineSeparator>
                    {/* <TimelineContent className={"trivia-gsFont"} style={{marginTop:"0.5em"}}>Inicia sesión con tu cuenta de Facebook</TimelineContent> */}
                    <TimelineContent className={"trivia-gsFont"} style={{marginTop:"0.5em"}}>Ingresa tus datos</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-stepsContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-steps"}>
                        03
                    </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gsFont"} style={{marginTop:"0.5em"}}>Responde correctamente las 10 preguntas en el menor tiempo posible</TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    )
}

export default Steps
