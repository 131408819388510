import React, { useState } from 'react';
import axios from 'axios';
// import { FacebookProvider, LoginButton } from 'react-facebook';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
//STYLES
import '../../Styles/GlobalStyle.css';

function Form(props){
    const [userInfoSignin, setUserInfoSignin] = useState({
        name:'',
        last_name: '',
        email: '',
        from: props.contest.contest_name
    });
    const [loading, setLoading] = useState(false);
        
        const handleChange = (event) => {
            setUserInfoSignin(userInfoSignin => ({...userInfoSignin, [event.target.name]:event.target.value}));
        };
        function handleSubmit(){
            setLoading(true);
            if (userInfoSignin.email !== "" && userInfoSignin.name !== "" && userInfoSignin.last_name !== "") {
                if(validateEmail(userInfoSignin.email)){
                    if(validateName(userInfoSignin.name)){
                        if(validateName(userInfoSignin.last_name)){
                            props.setError("");
                            let date = new Date();
                            // SEND THE PETIION TO THE SERVER
                            const user_data = {
                                name: userInfoSignin.name + " " + userInfoSignin.last_name,
                                email: userInfoSignin.email.toLowerCase(),
                                facebook_id: userInfoSignin.facebook_id,
                                from: userInfoSignin.from+"-FM",
                                contest_id:props.contest.contest_id,
                                registration_date: date.toISOString(),
                                user_ip: props.user_ip
                            }
                            const api_url = process.env.REACT_APP_API_URL+"user/emailsignin/";
                            axios.post(api_url, {"params":user_data})
                            .then(res => {
                                const response_lambda = res.data;
                                if (response_lambda.code === 200){
                                    if (response_lambda.data.status === "CORRECT") {
                                        if (response_lambda.data.user_data) {
                                            props.setUser(response_lambda.data);
                                            setLoading(false);
                                        } else {
                                            props.setError("Error. Si persite el error favor de contactarnos (código NCreatUs)");
                                            setLoading(false);
                                        }                                        
                                    } else {
                                        props.setError("Error. Si persite el error favor de contactarnos (código NCreatUs)");
                                        setLoading(false);
                                    }
                                }
                                else {
                                    props.setError("Error. Si persite el error favor de contactarnos (código SigninLamb-"+response_lambda.code+")");
                                    setLoading(false);
                                }
                            })
                            .catch(error => {
                                console.log('error', error);
                                props.setError("Error. Si persite el error favor de contactarnos (código Signin)");
                                setLoading(false);
                            })
                        }
                        else{
                            props.setError("*Ingresa un apellido válido (Sin espacios, ni acentos, ni números)");
                            setLoading(false);
                        }
                    }
                    else{
                        props.setError("*Ingresa un nombre válido (Sin espacios, ni acentos, ni números)");
                        setLoading(false);
                    }
                }
                else{
                    props.setError("*Ingresa un correo válido");
                    setLoading(false);
                }
            }
            else{
                props.setError("*Ingresa tu correo, nombre y apellido");
                setLoading(false);
            }
    }
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function validateName(name) {
        var re = /^[a-zA-Z]+$/;
        return re.test(String(name));
    }

    return (
        <div>
            
            <TextField
                // style={{margin:"0.2em", width:"12.4em"}}
                className={"trivia-loginForm"}
                autoComplete="email"
                name="email"
                variant="outlined"
                required
                id="email"
                label="Correo"
                onChange={(e) => {e.persist(); handleChange(e)}}
                />
                <br/>
            <TextField
                style={{margin:"0.2em", width:"6em"}}
                autoComplete="fname"
                name="name"
                variant="outlined"
                required
                id="name"
                label="Nombre"
                onChange={(e) => {e.persist(); handleChange(e)}}
                />
            <TextField
                style={{margin:"0.2em", width:"6em"}}
                autoComplete="lname"
                name="last_name"
                variant="outlined"
                required
                id="last_name"
                label="Apellido"
                onChange={(e) => {e.persist(); handleChange(e)}}
                />
            {!loading?
            <>
                {!props.disabled?
                     <button  className="button-form-login-style" id="button-form-login" onClick={(e) => {e.preventDefault(); handleSubmit()}}>
                         Continuar
                     </button>
                :
                    <button disabled className="button-form-login-style" style={{margin:"1em", width:"20em", backgroundColor:"rgba(23, 161, 80, 1)", color:"white", pointerEvents: "none", opacity: "0.4",}} id="button-form-login" onClick={(e) => e.preventDefault()}>
                         Continuar
                     </button>
                }
            </>     
            :
                <Button
                    disabled
                    style={{margin:"1em", width:"20em"}}
                    >
                    <CircularProgress/>
                </Button>
            }
        </div>
    );
}

export default Form;