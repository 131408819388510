import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';

//COMPONTETS
import QuestionsTrivia from './QuestionsTrivia';
import Timer from './Timer';

//TEST
// import api from './API';

function Trivia(props) {
    // const { id, name, email, contest } = { id:"TEST", name:"Alex Carcelo", email:"alex@hatch.mx", contest:"TEST" };
    const [error, setError] = useState("");
    const [startTrivia, setStartTrivia] = useState(false);
    const [startDateTrivia, setStartDateTrivia] = useState("");
    const [endTrivia, setEndTrivia] = useState(false);
    const [loading, setLoading] = useState(true);
    const [time, setTime] = useState("");
    const [userReadableTime, setUserReadableTime] = useState({});
    const [trivia, setTrivia] = useState({});
    const [contestUserId, setContestUserId] = useState("");
    const [textHelper, setTextHelper] = useState("");

    useEffect(() => {
        setLoading(true);
        // console.log('props', props)
        if(props.user.user_can_participate){
            setContestUserId(props.user.contest_user_id);
            setTrivia(props.user.trivia.trivia);
            setLoading(false);
        }
        else{
            setError(`Lo sentimos ${props.user.user_data.name} ya has participado. Mantente al pendiente de las redes sociales para encontrar más concursos de este estilo.`);
            setLoading(false);
        }
    }, [props])

    const handleStartTrivia = () => {
        let date = new Date();
        setStartDateTrivia(date.toISOString());
        setStartTrivia(true);
    }
    const handleEndTrivia = (userAnswers) => {
        setLoading(true);
        const params = {
            user_trivia:userAnswers,
            contest_user_id: contestUserId,
            start_date_trivia: startDateTrivia,
            total_time: time,
            contest_id: props.contestId
        }
        // Get the last trivia created from DB
        const api_url = process.env.REACT_APP_API_URL+"contest/checkanswers";
        axios.post(api_url, {params})
        .then(res => {
            const response = res.data.data;
            if (res.data.code === 200){
                if(response.status === "CORRECT"){
                    setTextHelper("RESPONDISTE CORRECTAMENTE "+response.number_correct_answers+" DE "+response.number_questions+" PREGUNTAS EN UN TIEMPO DE");
                    // console.log('user_answers gql' , user_answers);
                    const userReadableTime = toReadableTime(time);
                    setUserReadableTime(userReadableTime);
                    setEndTrivia(true);
                    setLoading(false);
                }
                else{
                    // console.log("ERR",response.error);
                    setError("Error. Si persiste el error favor de contactarnos (código: CheckAn)");
                    setLoading(false);
                }
            }
            else {
                // console.log('response.error', response.error);
                setError("Error. Si persiste el error favor de contactarnos (código: CheckAn-"+res.data.code+")");
                setLoading(false);
            }
        })
        .catch(error => {
            // console.log("ERROR", error);
            setError("Error. Si persiste el error favor de contactarnos (código: UpdateContestU)");
            setLoading(false);
        })
    }

    const toReadableTime = time => {
        let milliseconds = parseInt(time % 1000);
        let seconds = Math.floor((time / 1000) % 60);
        let minutes = Math.floor(time / (1000 * 60));
    
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        milliseconds = milliseconds < 100 ? ( milliseconds < 10 ) ? "00" + milliseconds : "0" + milliseconds: milliseconds;
        return {
            milliseconds,
            seconds,
            minutes
        };
    };
    return (
        <div>
            {!loading ?
                <>
                    {error === ""?
                        <>
                            {!startTrivia?
                                    <div style={{textAlign:"center"}}>
                                        <p className={"trivia-glFont"}>{props.user.user_data.name}</p>
                                        <p className={"trivia-bsFont"}>El correo asociado es: {props.user.user_data.email}</p>
                                        {/* <h5>{triviaDescription.name}</h5>
                                        <h6>{triviaDescription.description}</h6> */}
                                        <p className={"trivia-blFont"} style={{marginBottom:"1.2em"}}>&iquest;EST&Aacute;S LISTO PARA EMPEZAR&#63;</p>
                                        <p className={"trivia-gxsFont"} >Da clic en el bot&oacute;n para comenzar</p>
                                        <p className={"trivia-rmFont"}  style={{marginBottom:"1.2em"}}>&iexcl;MUCHA SUERTE&#33;</p>
                                        <Button onClick={handleStartTrivia} style={{backgroundColor:"black", color:"white", borderRadius:"1px", width:"200px"}}>
                                            <p className={"trivia-wmFont"}>EMPEZAR TRIVIA</p>
                                        </Button>
                                    </div>
                                :
                                <>
                                    {(endTrivia)?
                                        <div style={{textAlign:"center"}}>
                                            {("minutes" in userReadableTime && textHelper !== "")?
                                                <>
                                                    <p className={"trivia-glFont"} style={{marginBottom:"0.5em"}}>{props.user.user_data.name}</p>
                                                    <p className={"trivia-blFont"} style={{width:'20em', marginBottom:"1em"}}>{textHelper}</p>
                                                    <p className={"trivia-rlFont"} style={{marginBottom:"1em"}}>{`${userReadableTime.minutes}:${userReadableTime.seconds}:${userReadableTime.milliseconds}`}</p>
                                                    <p className={"trivia-gxsFont"} style={{width:'40em', textAlign:"center", margin: "0 auto"}}>Muchas gracias por participar. Mantente al pendiente de tu correo electr&oacute;nico, ya que si resultas ganador te contactaremos por este medio para pedirte tus datos.</p>
                                                </>
                                            :
                                                <></>
                                            }
                                        </div>
                                    :
                                        <div style={{textAlign:"center"}}>
                                            <Timer setTotalTime={setTime}/>
                                            <QuestionsTrivia trivia={trivia} handleEndTrivia={handleEndTrivia}/>
                                        </div>
                                    }
                                    
                                </>
                            }
                        </>
                        :
                        <p className={"trivia-rmFont"} style={{width:'20em', textAlign:"center", margin: "3em auto"}}>{error}</p>
                    }
                </>
                :
                <CircularProgress/>
            }
        </div>
    )
}

export default Trivia;
