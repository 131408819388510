import React, { useState, useEffect } from 'react';

//STYLES
import '../../Styles/GlobalStyle.css';

import title from '../../Assets/np-title.png';

function TimeIsOverContest({startDateContest}) {
    const [previousTime, setPreviousTime] = useState(null);
    const [timeInMilliseconds, setTimeInMilliseconds] = useState(new Date(startDateContest).getTime());
    const [time, setTime] = useState({
        hours:"00",
        minutes:"00",
        seconds:"00",
        days:"00"
    });
    useEffect(() => {
        const toTime = time => {
            let seconds = Math.floor((time / 1000) % 60);
            let minutes = Math.floor((time / (1000 * 60)) % 60);
            let hours = Math.floor((((time/1000) / (60 * 60)) % 60));
            let days = Math.floor(((time/1000) / (60 * 60)) / 24);
            
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            hours = hours > 23 ? hours/24 : hours;
            hours = hours < 10 ? "0" + hours : hours;
            
            // if (minutes >= 60) {
            //     let left = minutes % 60;
            //     hours+=left;
            // }
            return {
                seconds,
                minutes,
                hours,
                days
            };
        };
        let interval = setTimeout(() => {
            let time_difference = new Date(startDateContest) - Date.now();
            let new_milliseconds_time = time_difference;
            if (new_milliseconds_time < 0) {
                window.location.reload();
            }
            let new_time = toTime(new_milliseconds_time);
            setPreviousTime(Date.now());
            setTimeInMilliseconds(new_milliseconds_time);
            setTime(new_time);
        }, 100)
        return () => {
            clearInterval(interval);
        }
    }, [time,previousTime,timeInMilliseconds, startDateContest])
    return (
        <div className="login-mailingContainer">
            <img src={title} alt="np-title" className="login-mailingImgTitle"/>
            <p className={"trivia-blFont"} style={{margin:"2em 0 6em 0"}}>Faltan: <span className={"trivia-rlFont"}>{`${time.days} Días ${time.hours}:${time.minutes}:${time.seconds}`}</span> para la siguiente promoci&oacute;n</p>
        </div>
    )
}

export default TimeIsOverContest;
