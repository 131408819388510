import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';


const OptionsTrivia = ({ options, questionId, handleUserAnswers, numberTotalQuestions, currentIndex }) => {
    const [value, setValue] = React.useState("");
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState(" ");
    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText(" ");
        setError(false);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        if (value === ""){
            setHelperText('Selecciona una respuesta');
            setError(true);
        }
        else{
            // console.log('questionId, value', questionId, value)
            handleUserAnswers(questionId, value);
            setValue("");
        }
    };
    return (
        <form onSubmit={handleSubmit}>
            <FormControl component="fieldset" error={error}>
                <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange} style={{minHeight:"200px",textAlign:"center", alignContent:"center"}}>
                {options.map((option, index) =>{
                    return(
                        <FormControlLabel key={index} value={option} control={<Radio style={{color:"rgba(23, 161, 80, 1)"}}/>} label={<p style={{fontSize: "0.9em", fontWeight: "900"}}>{option}</p>} />
                    )
                })}
                </RadioGroup>
                <FormHelperText style={{textAlign:"center", alignContent:"center", margin:"0 auto"}}>{helperText}</FormHelperText>
                {!(currentIndex === (numberTotalQuestions - 1))?
                    <Button type="submit" style={{backgroundColor:"rgba(23, 161, 80, 1)", margin:"0 auto", color:"white", borderRadius:"1px", width:"130px", height:"24px"}}>
                        <p className={"trivia-wcmFont"}>SIGUIENTE</p>
                    </Button>
                :
                    <Button type="submit" style={{backgroundColor:"black", color:"white", margin:"0 auto", borderRadius:"1px", width:"130px", height:"24px"}}>
                        <p className={"trivia-wcmFont"}>TERMINAR</p>
                    </Button>
                }
            </FormControl>
        </form>
    )
}

export default OptionsTrivia;
