import React from 'react'
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot } from '@material-ui/lab';

const Bases = () => {
    return (
        <div>
            {/* <p className={"trivia-gsFont"}>Acepta los teéminos y lee las políticas de privacidad</p>
            <p className={"trivia-gsFont"}>Inicia sesión con tu cuenta de Facebook</p>
            <p className={"trivia-gsFont"}>Responde correctamente las 10 preguntas en el menor tiempo posible</p> */}
            <h4 className={"trivia-blFont"} style={{textAlign:'center', margin:'2em 0'}}>BASES DE LA DINÁMICA</h4>
            <Timeline>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        01
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Podrás participar en la trivia el día 24 de diciembre de 2020 de las 09:00 CST hasta las 18:00 CST.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        02
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Solo se podrá participar una vez por usuario de correo electrónico.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        03
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Debes registrarte con un correo electrónico válido.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        04
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Para ganar deberás responder las 10 preguntas de manera correcta y en el menor tiempo posible.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        05
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Los primeros tres participantes en contestar correctamente las diez preguntas en el menor tiempo se llevarán: 1 Jersey Away, 1 Sudadera, 1 Jersey de Entrenamiento, 1 Gorra.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        06
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Promoción válida solo en la República Mexicana.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        07
                    </TimelineDot>
                    <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Mantente al pendiente de tu correo electrónico, ya que si resultas ganador te contactaremos por este medio para solicitarte tus datos.</TimelineContent>
                </TimelineItem>
                <TimelineItem className={"trivia-basesContainer"}>
                    <TimelineSeparator>
                    <TimelineDot className={"trivia-bases"}>
                        08
                    </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent className={"trivia-gmFont"}>Los premios se enviarán a la dirección indicada por los ganadores.</TimelineContent>
                </TimelineItem>
            </Timeline>
        </div>
    )
}

export default Bases;
