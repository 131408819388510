import React from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import TagManager from 'react-gtm-module'
import './App.css';
import Amplify from 'aws-amplify';
import Availability from './Components/Availability';
// import Facebook from './Components/Facebook';
import Twitter from './Components/Twitter';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

const tagManagerArgs = {
  gtmId: 'GTM-NC7556Q'
  // gtmId: 'GTM'
};

TagManager.initialize(tagManagerArgs);

function App() {
  return (
    <div>
        <BrowserRouter> 
            <Switch >
                {/* <Route exact path="/Facebook" component={Facebook}/> */}
                <Route exact path="/twitter" component={Twitter}/>
                {/* <Route exact path="/trivia" component={LogIn}></Route> */}
                <Route path="/:oauth_token" component={Availability}></Route>
                <Route component={Availability}></Route>
            </Switch>
    	</BrowserRouter>
        {/*<button onClick={() => Auth.signOut()}>Sign out</button>*/}
    </div>
  );
}

export default App;

