import React, {useEffect} from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Bowser from "bowser"; // ES6 (and TypeScript with --esModuleInterop enabled)

//STYLES
import '../../Styles/GlobalStyle.css';
import '../../Styles/LogIn.css';

function Twitter(props){
	const endpoint = process.env.REACT_APP_API_URL;
	useEffect(() => {
		try {
			const windowUrl = window.location.search;
			const params = queryString.parse(windowUrl);
			if (params.oauth_verifier) {
				window.history.pushState({}, document.title, "/");
				props.setLoading(true);
				getOauthToken(params.oauth_verifier, params.oauth_token);
			}
		} catch (error) {
			
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	const onSuccess = (res) => {
		if (res.user) {
			let date = new Date();
			let user_info = {
				name: res.user.name,
				email: res.user.email,
				from: props.contest.contest_name+"-TW",
				contest_id: props.contest.contest_id,
				registration_date: date.toISOString(),
				user_ip: props.user_ip,
				twitter:{
					id: res.user.twitterProvider.id,
					token: res.user.twitterProvider.token,
					tokenSecret: res.user.twitterProvider.tokenSecret
				}
			}
			const api_url = process.env.REACT_APP_API_URL+"user/twittersignin/";
			axios.post(api_url, {"params":user_info})
			.then(res => {
				const response = res.data;
				if (response.code === 200){
					if (response.data.user_data) {
						props.setUser(response.data);
						props.setLoading(false);
					} else {
						props.setError("Error. Si persite el error favor de contactarnos (código NCreatUs)");
						props.setLoading(false);
					}
				}
				else {
					props.setError("Error. Si persite el error favor de contactarnos (código SigninLamb-"+res.data.code+")");
					props.setLoading(false);
				}
			})
			.catch(error => {
				props.setError("Error. Si persite el error favor de contactarnos (código Signin)");
				props.setLoading(false);
			})
		} else {
			props.setError("*Debes conceder los permisos a Mi Selección para poder continuar. La información de Twitter nos será de utilidad para contactarte si eres ganador.");
			props.setLoading(false);
		}
	};
	  
	const onFailed = (error) => {
		// setLoading(true);
		if (error.toString() === "Error: Popup has been closed by user") {
			props.setError("*Debes conceder los permisos a Mi Selección para poder continuar. La información de Twitter nos será de utilidad para contactarte si eres ganador.");
		} else {
			props.setError("Error. Intentalo de nuevo. Si persite el error favor de contactarnos (código TwitterLogin)");
		}
		props.setLoading(false);
	};
	  
	const handleClick = (e) => {
		e.preventDefault();
		window.dataLayer.push({
			event: 'Click-Twitter-login'
		});
		props.setLoading(true);
		getRequestToken();
	}

	function getRequestToken() {
		window.fetch(endpoint+"auth/twitter/token", {
			method: "POST",
		}).then(function (response) {
			return response.json();
		}).then(function (data) {
			if (data.oauth_token) {
				var authenticationUrl = "https://api.twitter.com/oauth/authorize?oauth_token=" + data.oauth_token + "&force_login=true";
			
				var w = 600;
				var h = 400;
				var left = window.innerWidth;
				var top = window.innerHeight / 2 - h / 2;
				var popup = null;
				try {
					const browser = Bowser.getParser(window.navigator.userAgent);
					if (browser.getPlatform().type !== "mobile"){
						popup = window.open("about:blank", "", "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
						popup.location = authenticationUrl;
						polling(popup);
					}
					else{
						popup = window.open(authenticationUrl, "", "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
					}
				} catch (error) {
					popup = window.open("about:blank", "", "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
					popup.location = authenticationUrl;
					polling(popup);
				}
			} else {
				onFailed("Error: Undefined error");
			}
		}).catch(function (error) {
			onFailed(error);
		});
	}
	function polling(popup) {
		var polling = setInterval(function () {
			if (!popup || popup.closed || popup.closed === undefined) {
				clearInterval(polling);
				onFailed(new Error("Popup has been closed by user"));
			}

			var closeDialog = function closeDialog() {
				clearInterval(polling);
				popup.close();
			};			
			try {
				// eslint-disable-next-line
				if (!popup.location.hostname.includes("api.twitter.com") && !popup.location.hostname == "") {
					if (popup.location.search) {
						var query = new URLSearchParams(popup.location.search);

						var oauthToken = query.get("oauth_token");
						var oauthVerifier = query.get("oauth_verifier");

						closeDialog();
						return getOauthToken(oauthVerifier, oauthToken);
					} else {
						closeDialog();
						onFailed(new Error("Popup has been closed by user"));
					}
				}
			} catch (error) {
				// Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
				// A hack to get around same-origin security policy errors in IE.
			}
		}, 500);
    }
	function getOauthToken(oAuthVerifier, oauthToken) {
		const login_endpoint = `${endpoint}auth/twitter/login?oauth_verifier=${oAuthVerifier}&oauth_token=${oauthToken}`;
		return window.fetch(login_endpoint, {
			method: "POST",
			}).then(function (response) {
				response.json().then(res => {
					if (res.error === "NO TOKEN"){
						props.setError("*Debes conceder los permisos a Mi Selección para poder continuar. La información de Twitter nos será de utilidad para contactarte si eres ganador.");
						props.setLoading(false);
					}
					else if (!res.user){
						props.setError("*Error. Si persite el error favor de contactarnos (código TwitterSignIn)");
						props.setLoading(false);
					}
					else{
						onSuccess(res);
					}
				})
				.catch(err => {
					props.setError("Error. Si persite el error favor de contactarnos (código TwitterSignIn)");
					props.setLoading(false);
				})
			}).catch(function (error) {
				onFailed(error);
			});
	}
	return (
		<>
			{!props.disabled?
				<div className={"twitter-button"} onClick={(e)=>handleClick(e)}>
					<span>Continuar con Twitter</span>
				</div>
			:
				<div className={"twitter-button"} style={{pointerEvents: "none", opacity: "0.4", cursor:"auto"}}>
					<span>Continuar con Twitter</span>
				</div>
			}         
        </>
	);
};

export default Twitter;