import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgress, Grid } from '@material-ui/core';

//COMPONENTS
import TimeIsOverContest from './Views/TimeIsOverContest';
import TimeLeftToStartContest from './Views/TimeLeftToStartContest';
import Home from './Views/Home';

//STYLES
import '../Styles/LogIn.css';
import '../Styles/GlobalStyle.css';

import logo from '../Assets/fmf_logo.png'

function Availability() {
    const [statusContest, setStatusContest] = useState("DEFAULT");
    const [contest, setContest] = useState(null);
    const [startDateContest, setStartDateContest] = useState(null);
    const [error, setError] = useState("");
    useEffect(() => {
        // axios.get("https://api.ipify.org/?format=json")
        const api_url = process.env.REACT_APP_API_URL+"contest/getstatuscontest";
        axios.get(api_url)
        .then(res => {
            // console.log('res', res);
            if (res.data.code === 200){
                if(res.data.data.status === "ABOUTTOSTART"){
                    window.dataLayer.push({
                        event: 'AboutToStart',
                        eventProps: {
                            category: "Page",
                            action: "AboutToStart",
                            label: "/",
                        }
                    });
                    setStatusContest(res.data.data.status);
                    setStartDateContest(res.data.data.startdate);
                }
                else if(res.data.data.status === "READY"){
                    setStatusContest(res.data.data.status);
                    setContest({"contest_id":res.data.data.contest_id, "contest_name":res.data.data.contest_name});
                }
                else{
                    window.dataLayer.push({
                        event: 'MailingSubscription',
                        eventProps: {
                            category: "Page",
                            action: "MailingSubscription",
                            label: "/",
                        }
                    });
                    setStatusContest(res.data.data.status);
                }
            }
            else {
                setError("Error en el sistema. Si el error persiste contáctanos. (código: GTStart)");
            }
        })
        .catch(error => {
            // console.log('error', error);
            setError("Error en el sistema. Si el error persiste contáctanos. (código: GTStart)");
        })
    }, [])
    return (
        <>
            {(error === "")?
                <>
                    {(statusContest==="DEFAULT")?
                        <div className="container">
                            <CircularProgress/>
                        </div>
                    :
                    <>
                        <div className="login-navbar">
                            <img src={logo} alt="FMF" className="fmf-logo"/>
                        </div>
                        {(statusContest==="READY")?
                            <div>
                                {(contest!=null)?
                                    <Home contest={contest}/>
                                    :
                                    <></>
                                }
                            </div>
                        :
                            <>
                                {(statusContest==="ABOUTTOSTART" && startDateContest!=null)?
                                    <TimeLeftToStartContest startDateContest={startDateContest}/>
                                :
                                    //TIMEISOVER
                                    <TimeIsOverContest/>
                                }   
                            </>
                        }   
                        <div className="footer-container">
                            <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                                <Grid item container xs={12} sm={4} direction="row" justify="center" alignItems="center" className="footer-section">
                                    <Grid item>
                                        <h3 className="footer-wlFont" style={{fontSize:"20.8px"}}>MI SELECCI&Oacute;N</h3>
                                        <a href={"https://miseleccion.mx/noticias/seleccion-mayor"} className="footer-link footer-wsFont">Selecci&oacute;n Nacional</a>
                                        <a href={"https://miseleccion.mx/noticias/femenil-mayor"} className="footer-link footer-wsFont">Selecci&oacute;n Nacional Femenil</a>
                                        <a href={"https://www.adidas.mx/mexico"} className="footer-link footer-wsFont">Tienda Oficial</a>
                                        <a href={"https://miseleccion.mx/multimedia"} className="footer-link footer-wsFont">Multimedia</a>
                                        <a href={"https://miseleccion.mx/"} className="footer-link footer-wsFont">Eliminatoria Mundialista</a>
                                        <a href={"https://www.adidas.mx/mexico"} className="footer-link footer-wsFont">Zona Adidas</a>
                                        <a href={"https://miseleccion.mx/aviso"} className="footer-link footer-wsFont">Aviso de Privacidad</a>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={4} direction="row" justify="center" alignItems="center" className="footer-section">
                                    <Grid item>
                                        <h3 className="footer-wlFont">DIRECCI&Oacute;N</h3>
                                        <p className="footer-wsFont">Av. Arboleda #101, Ex Hacienda Sant&iacute;n</p>
                                        <p className="footer-wsFont">San Mateo Otzacatipan, C.P. 50200</p>
                                        <p className="footer-wsFont">Toluca, Estado de M&eacute;xico.</p>
                                    </Grid>
                                </Grid>
                                <Grid item container xs={12} sm={4} direction="row" justify="center" alignItems="center" className="footer-section">
                                    <Grid item>
                                        <h3 className="footer-wlFont">CONTACTO</h3>
                                        <a href="mailto:mktdigital@fmf.mx" className="footer-wsFont footer-link">mktdigital@fmf.mx</a>
                                        <a href="tel:7225808000" className="footer-wsFont footer-link">(722) 580 8000</a>
                                        <br/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </>
                    }
                </>
            :
            <div className="container">
                ERROR: {error}
            </div>
            }
        </>
    )
}

export default Availability;
