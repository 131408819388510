import React, { useEffect, useState }  from 'react';

function Timer(props) {
    const [previousTime, setPreviousTime] = useState(null);
    const [timeInMilliseconds, setTimeInMilliseconds] = useState(0);
    const [time, setTime] = useState({
        minutes:"00",
        seconds:"00",
        milliseconds:"000"
    });
    useEffect(() => {
        const toTime = time => {
            let milliseconds = parseInt(time % 1000);
            let seconds = Math.floor((time / 1000) % 60);
            let minutes = Math.floor(time / (1000 * 60));
        
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            milliseconds = milliseconds < 100 ? ( milliseconds < 10 ) ? "00" + milliseconds : "0" + milliseconds: milliseconds;
            return {
                milliseconds,
                seconds,
                minutes
            };
        };
        let interval = setTimeout(() => {
            let previous_time = previousTime ? previousTime : Date.now();
            let time_difference = Date.now() - previous_time;
            let new_milliseconds_time = timeInMilliseconds + time_difference;
            let new_time = toTime(new_milliseconds_time);
            setPreviousTime(Date.now());
            setTimeInMilliseconds(new_milliseconds_time);
            setTime(new_time);
        }, 10)
        return () => {
            clearInterval(interval);
            props.setTotalTime(timeInMilliseconds);
        }
    }, [time,previousTime,timeInMilliseconds, props])
    return (
        <p className={"trivia-blFont"}>Tiempo: <span className={"trivia-rlFont"}>{`${time.minutes}:${time.seconds}:${time.milliseconds}`}</span></p>
    )
}

export default Timer
