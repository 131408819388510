import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import closeicon from '../Assets/close.png';
import CircularProgress from '@material-ui/core/CircularProgress';

//COMPONENTS
import Trivia from './Trivia';
import Steps from './Titles/Steps';
import Bases from './Titles/Bases';
import Twitter from './Auth/TwitterLogin';
import Facebook from './Auth/FacebookLogin';
import Form from './Auth/Form';

//STYLES
import '../Styles/GlobalStyle.css';

const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflowY:'scroll'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      maxWidth: '600px',
      maxHeight: '100% !important',
      overflowY:'scroll',
      outline:'none'
    },
}));

function Login(props){
    const [user, setUser] = useState({});
    const [error, setError] = useState("");
    const [contestUserData, setContestUserData] = useState({
        contest_id:props.contest.contest_id,
        user_ip:"none"
    });
    const [open, setOpen] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        try {
            let user_ip = "undefined";
            axios.get("https://api.ipify.org/?format=json")
            .then(res => {
                //console.log(res.data.ip);
                user_ip=res.data.ip;
                setContestUserData({contest_id:props.contest.contest_id, user_ip:user_ip})
            })
            .catch(error => {
                // console.log('error', error);
                user_ip="undefined";
                const api_url = process.env.REACT_APP_API_URL+"getuserip";
                axios.get(api_url)
                .then(res => {
                    // console.log('res', res);
                    if (res.data.code === 200){
                            user_ip=res.data.data.ip;
                            setContestUserData({contest_id:props.contest.contest_id, user_ip:user_ip})
                    }
                    else{
                        user_ip="undefined";
                        setContestUserData({contest_id:props.contest.contest_id, user_ip:user_ip})
                    }
                })
                .catch(error => {
                    // console.log('error', error);
                    user_ip="undefined";
                    setContestUserData({contest_id:props.contest.contest_id, user_ip:user_ip})
                })
            })
        } catch (e) {
            let user_ip="undefined";
            setContestUserData({contest_id:props.contest.contest_id, user_ip:user_ip})
        }
    }, [props])

    return (
        <div className="container">
            {!("user_data" in user)?
                <>
                    <h4 className={"trivia-blFont"} style={{width:'12em'}}>SIGUE ESTOS PASOS PARA PARTICIPAR</h4>
                    <Steps/>
                    <div className={"facebook-section"}>
                        {/* <p className={"trivia-bmFont"}>Inicia sesión y participa ahora</p> */}
                        <p className={"trivia-bmFont"}>Ingresa tus datos y participa ahora</p>
                        <form style={{textAlign:"center"}} noValidate>
                            {termsAccepted && props.contest?
                                <>
                                    {!loading?
                                        <>
                                            {(props.contest.contest_name.includes("SN"))?
                                                <>
                                                    <Facebook
                                                        contest={props.contest}
                                                        user_ip={contestUserData.user_ip}
                                                        setError={setError}
                                                        setUser={setUser}
                                                        disabled={false}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                        />
                                                    <Twitter
                                                        contest={props.contest}
                                                        user_ip={contestUserData.user_ip}
                                                        setError={setError}
                                                        setUser={setUser}
                                                        disabled={false}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                        />
                                                </>
                                                :
                                                <>
                                                    {(props.contest.contest_name.includes("FB"))?
                                                        <Facebook
                                                            contest={props.contest}
                                                            user_ip={contestUserData.user_ip}
                                                            setError={setError}
                                                            setUser={setUser}
                                                            disabled={false}
                                                            setLoading={setLoading}
                                                            loading={loading}
                                                            />
                                                        :
                                                        <>
                                                            {(props.contest.contest_name.includes("TW"))?
                                                                <Twitter
                                                                    contest={props.contest}
                                                                    user_ip={contestUserData.user_ip}
                                                                    setError={setError}
                                                                    setUser={setUser}
                                                                    disabled={false}
                                                                    setLoading={setLoading}
                                                                    loading={loading}
                                                                    />
                                                                :
                                                                <Form
                                                                    contest={props.contest}
                                                                    user_ip={contestUserData.user_ip}
                                                                    setError={setError}
                                                                    setUser={setUser}
                                                                    disabled={false}
                                                                    />
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                        :
                                        <CircularProgress/>
                                    }
                                </>
                                :
                                <>
                                    {!loading?
                                        <>
                                            {(props.contest.contest_name.includes("SN"))?
                                                <>
                                                    <Facebook
                                                        contest={props.contest}
                                                        user_ip={contestUserData.user_ip}
                                                        setError={setError}
                                                        setUser={setUser}
                                                        disabled={true}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                        />
                                                    <Twitter
                                                        contest={props.contest}
                                                        user_ip={contestUserData.user_ip}
                                                        setError={setError}
                                                        setUser={setUser}
                                                        disabled={true}
                                                        setLoading={setLoading}
                                                        loading={loading}
                                                        />
                                                </>
                                                :
                                                <>
                                                    {(props.contest.contest_name.includes("FB"))?
                                                        <Facebook
                                                            contest={props.contest}
                                                            user_ip={contestUserData.user_ip}
                                                            setError={setError}
                                                            setUser={setUser}
                                                            disabled={true}
                                                            setLoading={setLoading}
                                                            loading={loading}
                                                            />
                                                        :
                                                        <>
                                                            {(props.contest.contest_name.includes("TW"))?
                                                                <Twitter
                                                                    contest={props.contest}
                                                                    user_ip={contestUserData.user_ip}
                                                                    setError={setError}
                                                                    setUser={setUser}
                                                                    disabled={true}
                                                                    setLoading={setLoading}
                                                                    loading={loading}
                                                                    />
                                                                :
                                                                <Form
                                                                    contest={props.contest}
                                                                    user_ip={contestUserData.user_ip}
                                                                    setError={setError}
                                                                    setUser={setUser}
                                                                    disabled={true}
                                                                    />
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    :
                                        <CircularProgress/>
                                    }
                                </>
                            }
                            <p className={"trivia-rsFont"} style={{paddingTop:"0.5em", margin:"0"}}>{error}</p>
                            <p className={"trivia-rsFont"} style={error? {display:"none"}: {color:"white"}}>.</p>
                        </form>
                        <ListItem dense>
                            <Checkbox
                                edge="start"
                                color="primary"
                                className={"login-termsAndConditions"}
                                checked={termsAccepted}
                                onChange={()=>setTermsAccepted(!termsAccepted)}
                            />
                            <ListItemText primary={<span> He leído las <span id="button-rules" style={{color: "rgba(23, 161, 80, 1)", cursor: "pointer", textDecoration: "none"}} onClick={() => setOpen(true)}>bases</span> y acepto el <span id="button-privacy-policy" style={{color: "rgba(23, 161, 80, 1)", cursor: "pointer", textDecoration: "none"}} onClick={() => {window.open("https://miseleccion.mx/aviso", '_blank')}}> Aviso de Privacidad</span></span>} />
                        </ListItem>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className={classes.modal}
                            open={open}
                            onClose={() => setOpen(false)}
                            closeAfterTransition
                            
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={open}>
                            <div className={classes.paper}>
                                <img src={closeicon} alt="close_modal" style={{ width: '30px', height: '30px', cursor: "pointer", textAlign:'center', margin:'1em auto', display:'block' }} onClick={() => setOpen(false)}/>
                                <Bases/>
                            </div>
                            </Fade>
                        </Modal>
                    </div>
                </>
                :
                <>
                    {(contestUserData.user_ip !== "none")?
                        <Trivia user={user} contestId={contestUserData.contest_id}/>
                    :
                        <></>
                    }
                </>
            }
        </div>
    );
}

export default Login;
