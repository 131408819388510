import React from 'react';
//COMPONENTS
import Login from '../Login';
//STYLES
import '../../Styles/LogIn.css';
import '../../Styles/GlobalStyle.css';

import banner from '../../Assets/banner.jpg';
import side from '../../Assets/side.png';

function Home(props) {
    window.dataLayer.push({
        event: 'Contest',
        eventProps: {
            category: "Page",
            action: "Contest",
            label: "/",
        }
    });
    return (
        <>
            {(props.contest.contest_id && props.contest.contest_name)?
            <>
                <div>
                    <img className="login-banner" style={{width:"100%", height:"100%", objectFit: "cover", overflow: "hidden"}} src={banner} alt="FMF Dinámica"/>
                    <div style={{minHeight:"30em"}}>
                        <img className="login-side-left" src={side} alt="FMF Dinámica"/>
                        <Login
                            contest={props.contest}
                            />
                    </div>
                    <img className="login-side-right" src={side} alt="FMF Dinámica"/>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                </>
                :
                <></>    
            }
        </>
    )
}

export default Home;
