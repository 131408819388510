import React, { useState } from 'react';
import OptionsTrivia from './OptionsTrivia';

const QuestionsTrivia = ({ trivia, handleEndTrivia }) => {
    const [answers, setAnswers] = useState({});
    const [indexCurrentQuestion, setIndexCurrentQuestion] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(trivia[0]);
    const handleUserAnswers = (id, answer) => {
        let index = indexCurrentQuestion;
        let user_answers = answers;
        if (index < (trivia.length - 1)) {
            index = indexCurrentQuestion + 1;
            setAnswers(answers => ({...answers, [id]:answer}));
            setIndexCurrentQuestion(index);
            setCurrentQuestion(trivia[index]);
        } else {
            user_answers={...user_answers, [id]:answer}
            // console.log('answers', user_answers)
            handleEndTrivia(user_answers);
        }
    }
    return (
        <div>
            <div style={{minHeight:"100px"}} className={"trivia-question"}>
                <p className={"trivia-bcmFont"}>{indexCurrentQuestion+1}/{trivia.length}</p>
                <p className={"trivia-bmFont"}>{currentQuestion.question}</p>
            </div>
            <OptionsTrivia options={currentQuestion.options} questionId={currentQuestion.id} handleUserAnswers={handleUserAnswers} numberTotalQuestions={trivia.length} currentIndex={indexCurrentQuestion}/>
        </div>
    )
}

export default QuestionsTrivia;
