import React, { useState, forwardRef  } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import DatePicker from "react-datepicker";
import Checkbox from '@material-ui/core/Checkbox';

// IMAGES
import title from '../../Assets/np-title.png';

//STYLES
import '../../Styles/GlobalStyle.css';
import "react-datepicker/dist/react-datepicker.css";

function TimeIsOverContest() {
    const [user, setUser] = useState({
        name:'',
        last_name: '',
        email: '',
        birthday: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [form, setForm] = useState(true);
    const [termsAccepted, setTermsAccepted] = useState(false);

    function range(size, startAt) {
        return [...Array(size).keys()].map(i => i + startAt);
    }
    const currentYear = new Date().getFullYear() + 1;
    const years = range(100, currentYear-100);
    const months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
    ];
    const onChangeDate = (date) => {
        const pickedDate = date.toLocaleDateString('en-US',{day: '2-digit',month: '2-digit',year: 'numeric'})
        //Displaying the picked date to the user
        var splitedDate = pickedDate.split("/");
        var selectedDate = splitedDate[1] + "-" + (splitedDate[0]) + "-" + splitedDate[2];
        setUser({...user, birthday:selectedDate});
      }
      const CustomInput =forwardRef((props, ref)  => {
        return <TextField
            style={{display: "block", cursor: "pointer", color:"gray"}}
            fullWidth
            name='birthdate'
            id="date"
            label="Fecha de nacimiento *"
            value={user.birthday}
            InputProps={{
                readOnly: true,
            }}
            variant="outlined"
            ref={ref}
            onClick={(e)=>{e.preventDefault(); props.onClick()}}
            className="CustomInputStyle"
            />
      });
    const handleChange = (event) => {
        setUser(user => ({...user, [event.target.name]:event.target.value}));
    };
    function handleSubmit(){
        window.dataLayer.push({
			event: 'button-mailing-subscription'
		});
        setLoading(true);
        if (user.email !== "" && user.name !== "" && user.last_name !== "" && user.birthday !== '') {
            if(validateEmail(user.email)){
                if(validateName(user.name)){
                    if(validateName(user.last_name)){
                        if (getAge(user.birthday) >= 5) {
                            setError("");
                            axios.get("https://api.ipify.org/?format=json")
                            .then(res => {
                                let user_ip=res.data.ip;
                                const user_data = {
                                    email: user.email.toLowerCase(),
                                    name: user.name.toUpperCase(),
                                    last_name: user.last_name.toUpperCase(),
                                    birthday: user.birthday,
                                    user_ip: user_ip
                                }
                                const api_url = process.env.REACT_APP_API_URL+"user/mailingsubscription";
                                axios.post(api_url, user_data)
                                .then(res => {
                                    if (res.status === 200){
                                        setLoading(false);
                                        setForm(false);
                                    }
                                    else{
                                        setError("Error. Intenta más tarde");
                                        setLoading(false);
                                    }
                                })
                                .catch(error => {
                                    setError("Error. Intenta más tarde");
                                    setLoading(false);
                                })
                            })
                            .catch(error => {
                                setError("Error. Intenta más tarde");
                                setLoading(false);
                            })
                        } else {
                            setError("*Ingresa una fecha de nacimiento válida");
                            setLoading(false);
                        }
                    }
                    else{
                        setError("*Ingresa un apellido válido (Sin espacios, ni acentos, ni números)");
                        setLoading(false);
                    }
                }
                else{
                    setError("*Ingresa un nombre válido (Sin espacios, ni acentos, ni números)");
                    setLoading(false);
                }
            }
            else{
                setError("*Ingresa un correo válido");
                setLoading(false);
            }
        }
        else{
            setError("*Ingresa tu nombre, apellido, correo y fecha de nacimiento");
            setLoading(false);
        }
    }
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    function validateName(name) {
        var re = /^[a-zA-Z]+$/;
        return re.test(String(name));
    }
    function getAge(dateString) {
        var today = new Date();
        var splitedDate = dateString.split("-");
        var selectedDate = splitedDate[2] + "-" + (splitedDate[1]) + "-" + splitedDate[0]+"T12:00:00Z";
        var birthDate = new Date(selectedDate);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
      }
    
    return (
        <div className="login-mailingContainer" id="page_contest">
            <img src={title} alt="promociones-fmf" className="login-mailingImgTitle"/>
            {form? 
                <>
                <div className="login-mailingTitle">
                    <p className="trivia-bmFont login-mailingtitleText">Registra tus datos a continuaci&oacute;n para recibir todas las novedades de nuestras dinámicas</p>
                </div>
                <Grid container direction="row" justify="center" alignItems="center" spacing={2} className="login-mailingForm" style={{width:'100%', margin:'0'}}>
                    <Grid item xs={12}>
                    <div data-dp-form="v%2fca%2f0nkVo0K%2f0dZFYmlvA%3d%3d"></div>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // style={{margin:"0.2em", width:"6em"}}
                            fullWidth
                            autoComplete="fname"
                            name="name"
                            variant="outlined"
                            required
                            id="name"
                            label="Nombre"
                            onChange={(e) => {e.persist(); handleChange(e)}}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // style={{margin:"0.2em", width:"6em"}}
                            fullWidth
                            autoComplete="lname"
                            name="last_name"
                            variant="outlined"
                            required
                            id="last_name"
                            label="Apellido"
                            onChange={(e) => {e.persist(); handleChange(e)}}
                            />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            // style={{margin:"0.2em", width:"12.4em"}}
                            fullWidth
                            className={"trivia-loginForm"}
                            autoComplete="email"
                            name="email"
                            variant="outlined"
                            required
                            id="email"
                            label="Correo"
                            onChange={(e) => {e.persist(); handleChange(e)}}
                            />
                    </Grid>
                    <Grid item xs={12} style={{ padding: "15px 8px 8px 8px" }}>
                        <DatePicker
                            onChange={date => onChangeDate(date)}
                            showYearDropdown
                            showMonthDropdown
                            popperPlacement="top-center"
                            style={{ cursor: "pointer"}}
                            popperModifiers={{
                                offset: {
                                    enabled: true,
                                    offset: "0em 40em"
                                }
                            }}
                            customInput={<CustomInput />}
                            renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled
                            }) => (
                                <div>
                                    <button onClick={(e) => {e.preventDefault(); decreaseMonth()}} disabled={prevMonthButtonDisabled}>
                                        {"<"}
                                    </button>
                                    <select value={date.getFullYear()} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                        ))}
                                    </select>
                            
                                    <select value={months[date.getMonth()]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}>
                                        {months.map(option => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                        ))}
                                    </select>
                            
                                    <button onClick={(e) => {e.preventDefault(); increaseMonth()}} disabled={nextMonthButtonDisabled}>
                                        {">"}
                                    </button>
                                </div>
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{textAlign:"right"}}>
                            <p className={"trivia-rsFont"} style={{color:"gray"}}>*Campos obligatorios</p>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div style={{textAlign:"center"}}>
                            <Checkbox
                                edge="start"
                                color="primary"
                                checked={termsAccepted}
                                onChange={()=>setTermsAccepted(!termsAccepted)}
                            />
                            <span className={"trivia-bmFont"}>He leído y acepto la <span id="button-privacy-policy" style={{color: "rgba(23, 161, 80, 1)", cursor: "pointer", textDecoration: "none"}} onClick={() => {window.open("https://miseleccion.mx/aviso", '_blank')}}> Pol&iacute;tica de Privacidad</span></span>
                        </div>
                    </Grid>
                    {!loading?
                        <>
                            {termsAccepted?
                                <Button
                                style={{margin:"1em", width:"20em", backgroundColor:"#404040", color:"white"}}
                                type="submit"
                                variant="contained"
                                onClick={(e) => {e.preventDefault(); handleSubmit()}}
                                >
                                Enviar
                            </Button>
                            :
                                <Button
                                    style={{margin:"1em", width:"20em", backgroundColor:"#404040", color:"white", opacity: "0.4"}}
                                    type="submit"
                                    variant="contained"
                                    disabled
                                    onClick={(e) => {e.preventDefault()}}
                                    >
                                    Enviar
                                </Button>
                            }
                        </>
                    :
                        <Button
                            disabled
                            style={{margin:"1em", width:"20em"}}
                            onClick={(e) => {e.preventDefault()}}
                            >
                            <CircularProgress/>
                        </Button>
                    }
                    <Grid item xs={12}>
                        <div style={{textAlign:"center"}}>
                            <p className={"trivia-rmFont"} style={{paddingTop:"0.5em"}}>{error}</p>
                            <p className={"trivia-rmFont"} style={error? {display:"none"}: {color:"white"}}>.</p>
                        </div>
                        <br/>
                    </Grid>
                </Grid>
                </>
            :
            <div className="login-mailingTitle">
                <p className="trivia-bmFont login-mailingtitleText" style={{color:"#A81127", fontWeight:"bold"}}>¡Gracias! Te llegar&aacute; un correo para confirmar tu suscripci&oacute;n</p>
            </div>
            }
        </div>
    )
}

export default TimeIsOverContest;
