import React, {useEffect} from 'react';
import axios from 'axios';
import queryString from 'query-string';
import Bowser from "bowser"; // ES6 (and TypeScript with --esModuleInterop enabled)

//STYLES
import '../../Styles/GlobalStyle.css';

function Facebook(props){
    const endpoint = process.env.REACT_APP_API_URL;
    const redirect_uri = process.env.REACT_APP_ENV === "prod" ? "https://promociones.miseleccion.mx/" : "https://192.168.0.15:3000/";
    const app_id = process.env.REACT_APP_ENV === "prod" ? "805393786891504" : "765268744038826";
    
	useEffect(() => {
		try {
			const windowUrl = window.location.search;
            const params = queryString.parse(windowUrl);
			if (params.code) {
                window.history.pushState({}, document.title, "/");
				props.setLoading(true);
				getAccessToken(params.code);
			}
		} catch (error) {
			
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    function getAccessToken(code) {
		const login_endpoint = `${endpoint}auth/facebook/token?code=${code}`;
		return window.fetch(login_endpoint, {
			method: "POST",
        }).then(function (response) {
            response.json().then(res => {
                if (res.error === "NO TOKEN"){
                    props.setError("*Debes conceder los permisos a Mi Selección para poder continuar. La información de Twitter nos será de utilidad para contactarte si eres ganador.");
                    props.setLoading(false);
                }
                else if (res.error === "EXPIRED TOKEN" || !res.profile.email){
                    props.setError("Error. Si persite el error favor de contactarnos (código FBTE)");
                    props.setLoading(false);
                }
                else{
                    handleFacebookResponse(res);
                }
            })
            .catch(err => {
                props.setError("Error. Si persite el error favor de contactarnos (código FacebookSignIn)");
                props.setLoading(false);
            })
        }).catch(function (error) {
            props.setError("Error. Si persite el error favor de contactarnos (código FacebookSignIn)");
            props.setLoading(false);
        });
	}
    const handleFacebookResponse = (data) => {
        try {
            if (data.profile.email) {
                let date = new Date();
                const user_info = {
                    name: data.profile.name,
                    email: data.profile.email,
                    from: props.contest.contest_name+"-FB",
                    contest_id: props.contest.contest_id,
                    registration_date: date.toISOString(),
                    user_ip: props.user_ip,
                    facebook:{
                        id: data.tokenDetail.userID,
                        token: data.tokenDetail.accessToken,
                        tokenSecret: null
                    }
                }
                const api_url = process.env.REACT_APP_API_URL+"user/facebooksignin/";
                axios.post(api_url, {"params":user_info})
                .then(res => {
                    const response = res.data;
                    if (response.code === 200){
                        if (response.data.user_data) {
                            props.setUser(response.data);
                            props.setLoading(false);
                        } else {
                            props.setError("Error. Si persite el error favor de contactarnos (código NCreatUs)");
                            props.setLoading(false);
                        }
                    }
                    else {
                        props.setError("Error. Si persite el error favor de contactarnos (código SigninLamb-"+res.data.code+")");
                        props.setLoading(false);
                    }
                })
                .catch(error => {
                    props.setError("Error. Si persite el error favor de contactarnos (código Signin)");
                    props.setLoading(false);
                })
            } else {
                props.setError("*Debes conceder los permisos a Mi Selección para poder continuar. Tu correo de Facebook nos será de utilidad para contactarte si eres ganador.");
                props.setLoading(false);
            }
        } catch (error) {
            props.setError("Error. Si persite el error favor de contactarnos (código FBSignin)");
            props.setLoading(false);
        }
        
    }
    const handleClick = (e) => {
		e.preventDefault();
		window.dataLayer.push({
			event: 'Click-Facebook-login'
		});
        props.setLoading(true);
        var w = 600;
        var h = 400;
        var left = window.innerWidth;
        var top = window.innerHeight / 2 - h / 2;
        const login_url = "https://www.facebook.com/v9.0/dialog/oauth?client_id="+app_id+"&redirect_uri="+redirect_uri;
        var popup = null;
        try {
            const browser = Bowser.getParser(window.navigator.userAgent);
            if (browser.getPlatform().type !== "mobile"){
                popup = window.open("about:blank", "", "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
                popup.location = login_url;
                polling(popup);
            }
            else{
                popup = window.open(login_url, "", "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
            }
        } catch (error) {
            popup = window.open("about:blank", "", "width=" + w + ", height=" + h + ", top=" + top + ", left=" + left);
            popup.location = login_url;
            polling(popup);
        }
    }
    function polling(popup) {
		var polling = setInterval(function () {
			if (!popup || popup.closed || popup.closed === undefined) {
				clearInterval(polling);
				onFailed(new Error("Popup has been closed by user"));
			}

			var closeDialog = function closeDialog() {
				clearInterval(polling);
				popup.close();
			};			
			try {
                // eslint-disable-next-line
				if (!popup.location.hostname.includes("facebook") && !popup.location.hostname == "") {
					if (popup.location.search) {
						const query = new URLSearchParams(popup.location.search);

                        const code = query.get("code");

                        closeDialog();
                        return getAccessToken(code);
                    } else {
                        closeDialog();
                        onFailed(new Error("Popup has been closed by user"));
                    }
                }
			} catch (error) {
				// Ignore DOMException: Blocked a frame with origin from accessing a cross-origin frame.
				// A hack to get around same-origin security policy errors in IE.
			}
		}, 500);
    }
    const onFailed = (error) => {
		if (error.toString() === "Error: Popup has been closed by user") {
			props.setError("*Debes conceder los permisos a Mi Selección para poder continuar. La información de Twitter nos será de utilidad para contactarte si eres ganador.");
		} else {
			props.setError("Error. Intentalo de nuevo. Si persite el error favor de contactarnos (código TwitterLogin)");
		}
		props.setLoading(false);
	};
    return (
        <div style={{margin:"5px 0"}}>
            {!props.disabled?
                <div onClick={handleClick} className={"facebook-button"}><span>Continuar con Facebook</span></div>
                :
                <div className={"facebook-button"} style={{opacity: "0.4", cursor:"auto", disabled:"true", pointerEvents:"none"}}><span>Continuar con Facebook</span></div>
            }
        </div>
    );
}

export default Facebook;
